<template>
  <div class="content">
    <h1>Page Not Found</h1>

    <p class="text-center">
      We're sorry, but the page you're trying to access does not exist. We
      apologize for this inconvenience.
    </p>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound',
  metaInfo: {
    title: 'Page Not Found',
  },
};
</script>
